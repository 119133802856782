<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-3">
    <v-card elevation="24" dark>
      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide, i) in purchase.item.images" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list three-line dense>
        <v-list-item @click="() => {}">
          <v-list-item-content>
            <v-list-item-title class="fonte" style="font-size: 14pt;">{{
              purchase.item.title
            }}</v-list-item-title>
            <v-list-item-title class="fonte orange--text">
              Ganho total:
              {{ purchase.item.total_percent_gain }}%</v-list-item-title
            >
            <v-list-item-subtitle class="fonte font-weight-thin">{{
              purchase.item.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-expansion-panels flat>
            <v-expansion-panel>
                <v-expansion-panel-header class="fonte orange--text"> Veja o Andamento </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-timeline align-top dense>
                        <v-timeline-item :color="status.color" small :key="status" v-for="status in purchase.status">
                            <span class="fonte"> {{ status.status }}</span>
                            <span class="grey--text fonte"> ({{ $moment(status.date).format('DD/MM/YYYY [às] HH:mm') }}) </span>
                        </v-timeline-item>
                    </v-timeline>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["purchase", "fullscreen", "priceless"],
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
