<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <template v-for="purchase in getPurchases">
        <PurchaseCareer :key="purchase._id" :purchase="purchase" v-if="purchase.purchase_type==='career'" />
        <PurchaseInvestment :key="purchase._id" :purchase="purchase" v-if="purchase.purchase_type==='investment'" />
        <PurchaseProduct :key="purchase._id" :purchase="purchase" v-if="purchase.purchase_type==='product'" />
      </template>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseCareer from './components/PurchaseCareer'
import PurchaseInvestment from './components/PurchaseInvestment'
import PurchaseProduct from './components/PurchaseProduct'
export default {
  components: {
    PurchaseCareer,
    PurchaseInvestment,
    PurchaseProduct
  },
  computed: {
    ...mapGetters(["getPurchases"])
  },
  methods: {
    ...mapActions(["listPurchases"])
  },
  created() {
    this.listPurchases();
  }
};
</script>
